





































































































































import { Component, Vue } from 'vue-property-decorator'
// import { Firmware, firmwareCollection } from '@/models/firmwares'
import FirmwareItem from '@/components/firmware/item.vue'
import FirmwareForm from '@/components/firmware/form.vue'
import HelpFirmware from '@/components/help/firmware.vue'
import firmwareStore from '@/store/firmware'

@Component({ components: { FirmwareItem, FirmwareForm, HelpFirmware } })
export default class FirmwareList extends Vue {
  dialog = false
  // itemsPerPageArray = [4, 8, 16, 32, 64]
  search = ''
  filter = {}
  sortDesc = true
  page = 1
  // itemsPerPage = 16
  sortBy = 'createdAt'
  keys = [
    { value: 'name', text: '이름' },
    { value: 'modelNo', text: '대상모델' },
    { value: 'version', text: '버전' },
    { value: 'remark', text: '설명' },
    { value: 'bufferSize', text: '전송크기' },
    { value: 'createdAt', text: '생성일' },
    { value: 'updatedAt', text: '수정일' },
    { value: 'fileName', text: '파일명' },
    { value: 'fileSize', text: '파일크기' }
  ]

  expanded = false

  itemsPerPage = this.breakpointPage

  dialogHelp = false

  get items () {
    return firmwareStore.items
  }

  get searchItems () {
    return firmwareStore.items.map(item => {
      return {
        name: item.name,
        modelNo: item.modelNo,
        version: item.version,
        versionEx: item.version.split('').join('.'),
        remark: item.remark,
        bufferSize: item.bufferSize.toString(),
        createdAt: item.createdAt?.getTime().toString(),
        updatedAt: item.updatedAt?.getTime().toString(),
        fileName: item.fileName,
        fileSize: item.fileSize.toString()
      }
    })
  }

  getItem (name: string) {
    return firmwareStore.items.find(item => item.name === name)
  }

  get xs () { return this.$vuetify.breakpoint.xs }

  get breakpoint () {
    return 12 * 4 / this.breakpointPage
  }

  get breakpointPage () {
    let page = 1
    if (this.$vuetify.breakpoint.xs) page = 1 * 4
    else if (this.$vuetify.breakpoint.sm) page = 2 * 4
    else if (this.$vuetify.breakpoint.md) page = 3 * 4
    else if (this.$vuetify.breakpoint.lg) page = 4 * 4
    else if (this.$vuetify.breakpoint.xl) page = 6 * 4
    return page
  }

  get footerProps () {
    const pageOptions = [
      this.breakpointPage * 1,
      this.breakpointPage * 2,
      this.breakpointPage * 4,
      this.breakpointPage * 8
    ]
    if (!this.xs) {
      return {
        'items-per-page-options': pageOptions
      }
    }
    return {
      'items-per-page-options': pageOptions,
      itemsPerPageText: ''
    }
  }
}
