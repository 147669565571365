








import { Vue, Component, Prop } from 'vue-property-decorator'
import bytes from 'bytes'

@Component
export default class DisplayBytes extends Vue {
  @Prop({ type: Number, required: true }) readonly size!: number;

  get displaySize () {
    return bytes(this.size, { unitSeparator: ' ' })
  }
}
