
















































































































































































import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator'
import firmwareCollection, { Firmware } from '@/models/firmwares'
import deviceCollection from '@/models/devices'
import DisplayTime from '@/components/common/display-time.vue'
import DisplayBytes from '@/components/common/display-bytes.vue'
import modelStore from '@/store/model'
import { swalUpdate, swalDelete } from '@/plugins/alert'
import logAdd from '@/components/log/add'

@Component({
  components: { DisplayTime, DisplayBytes },
  filters: {
    noContent (s: string) {
      if (!s) return '내용 없음'
      return s
    }
  }
})
export default class FirmwareItem extends Vue {
  @Prop({ type: Object, required: true }) firmware!: Firmware
  @Prop({ type: Boolean, required: true }) expandedAll!: boolean
  storageRef = this.$firebase.storage().ref('firmwares').child(this.firmware.id).child(this.firmware.fileName)
  dialog = false

  loading = false
  modelNo = this.firmware.modelNo
  name = this.firmware.name
  bufferSize = this.firmware.bufferSize
  version = this.firmware.version.split('').join('.')
  remark = this.firmware.remark

  model = modelStore
  expanded = this.expandedAll

  @Ref('form') form!: HTMLElement & { validate(): boolean; reset(): void }

  @Watch('dialog')
  onDialogOpened (n: boolean) {
    if (!n) return
    this.modelNo = this.firmware.modelNo
    this.name = this.firmware.name
    this.bufferSize = this.firmware.bufferSize
    this.version = this.firmware.version.split('').join('.')
  }

  @Watch('expandedAll')
  onChangeExpandedAll (expanded: boolean) {
    this.expanded = expanded
  }

  async download () {
    const ref = this.$firebase.storage().ref('firmwares').child(this.firmware.name).child(this.firmware.fileName)
    const url = await ref.getDownloadURL()
    const a: HTMLAnchorElement = document.createElement('A') as HTMLAnchorElement
    a.href = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  async remove () {
    const r = await swalDelete()
    if (!r.value) return

    const ref = firmwareCollection.doc(this.firmware.id)

    const sn = await deviceCollection.where('firmware', '==', ref).limit(1).get()
    if (!sn.empty) throw Error('적용된 펌웨어가 있습니다 적용된 장치들의 펌웨어를 다른 것으로 교체하세요')

    await firmwareCollection.doc(this.firmware.id).delete()
    logAdd('firemware', this.firmware.id, '펌웨어 삭제', true, '/firmware')
  }

  async submit () {
    if (!this.form.validate()) throw new Error('입력 양식을 채워주세요')

    const r = await swalUpdate()
    if (!r.value) return
    try {
      this.loading = true
      const fw = {
        modelNo: this.modelNo,
        bufferSize: Number(this.bufferSize),
        version: this.withoutDotVersion,
        remark: this.remark
      }

      const ms = ['펌웨어 수정:']
      if (this.firmware.modelNo !== fw.modelNo) ms.push(`모델 ${this.firmware.modelNo} -> ${fw.modelNo}`)
      if (this.firmware.bufferSize !== fw.bufferSize) ms.push(`전송량 ${this.firmware.bufferSize} -> ${fw.bufferSize}`)
      if (this.firmware.version !== fw.version) ms.push(`버전 ${this.firmware.version} -> ${fw.version}`)
      if (this.firmware.remark !== fw.remark) ms.push(`비고 ${this.firmware.remark} -> ${fw.remark}`)

      logAdd('firemware', this.firmware.id, ms.join(' '), true, '/firmware')
      await firmwareCollection.doc(this.firmware.id).set(fw, { merge: true })
    } finally {
      this.loading = false
      this.dialog = false
    }
  }

  get modelSelects () {
    const ls = modelStore.items.map(model => {
      const count = typeof model.deviceCount === 'number' ? model.deviceCount : 0
      return { value: model.id, text: `${model.id} (${count})`, deviceCount: count }
    })
    return ls
  }

  get withoutDotVersion () { return this.version.split('.').join('') }
}
