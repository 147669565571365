




import { Component, Vue } from 'vue-property-decorator'
import FirmwareList from '@/components/firmware/list.vue'

@Component({ components: { FirmwareList } })
export default class ViewFirmware extends Vue {

}
