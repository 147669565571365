

































































import { Component, Vue, Ref } from 'vue-property-decorator'
import firmwareCollection, { Firmware } from '@/models/firmwares'
import modelStore from '@/store/model'
import logAdd from '@/components/log/add'

@Component({
})
export default class FirmwareForm extends Vue {
  file: File | null = null
  loading = false
  modelNo = ''
  name = ''
  bufferSize = 4096
  remark = ''
  version = '0.0.0.0'

  model = modelStore

  @Ref('form') form!: HTMLElement & { validate(): boolean; reset(): void }

  change (file: File) {
    this.file = file
  }

  async submit () {
    if (!this.form.validate()) throw new Error('error')
    if (!this.file) throw new Error('submit err')
    try {
      this.loading = true
      const id = this.name
      const fsn = await firmwareCollection.doc(id).get()
      if (fsn.exists) throw new Error('이미 존재하는 펌웨어 입니다')
      await this.$firebase.storage().ref('firmwares').child(id).child(this.file.name).put(this.file)

      const firmware = new Firmware(
        id,
        this.file.name,
        this.file.size,
        this.modelNo,
        this.name,
        Number(this.bufferSize),
        this.withoutDotVersion,
        this.remark
      )
      await firmwareCollection.doc(id).set(firmware)
      logAdd('firemware', id, '펌웨어 등록', true, '/firmware')
    } finally {
      this.loading = false
      this.form.reset()
      this.$emit('close')
    }
  }

  get modelSelects () {
    const ls = modelStore.items.map(model => {
      const count = typeof model.deviceCount === 'number' ? model.deviceCount : 0
      return { value: model.id, text: `${model.id} (${count})`, deviceCount: count }
    })
    return ls
  }

  get withoutDotVersion () { return this.version.split('.').join('') }
}
